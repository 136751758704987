
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'MActivityBoilerplate',
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'objective';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const fieldIndex = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-personal-objective';
          }
          return false;
        }
      );
      return fieldIndex;
    });

    const fieldValue = computed({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const participantData = computed({
      get: () => fieldValue.value.value,
      set: newVal => {
        fieldValue.value.value = newVal;
      }
    });

    const organizerData = computed({
      get: () => fieldValue.value.setup,
      set: newVal => {
        fieldValue.value.setup = newVal;
      }
    });

    const organizerAction = {};
    const participantActions = {};
    return {
      fieldValue,

      participantActions,
      organizerAction
    };
  }
});
